import { useState, useEffect, useContext, useRef } from "react"
import { useWindowSize } from "react-use"
import useRaf from "@rooks/use-raf"

import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { followPath } from "../three/camera/cameraMovement"
import { followKeyframes } from "../three/camera/cameraGLBMovement"
import { TransformControls } from "../three/controls/controls"
import { modelLoader, dracoLoader } from "../three/loaders/loaders"
import {
  EffectComposer,
  RenderPass,
  ShaderPass,
  UnrealBloomPass,
  BokehPass,
} from "../three/postProcessing/postProcessing"
import {
  ColorifyShaderOrange,
  FXAAShader,
  BrightnessContrastShader,
  HueSaturationShader,
} from "../three/shaders/shaders"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import {
  vert,
  frag,
  vertflame,
  fragflame,
  vertcylinder,
  fragcylinder,
  fireCylinder,
  fireFlame,
  fireMesh,
  fireUpdate,
  options,
} from "../three/shaders/FireShaders.js"

// console.log(vert)
// let dcoLoader = new DRACOLoader();
// dcoLoader.setPath("/nyio/glb/")
// // Specify path to a folder containing WASM/JS decoding libraries.
// dcoLoader.setDecoderPath( 'three/examples/js/libs/draco/' );

// // Optional: Pre-fetch Draco WASM/JS module.
// dcoLoader.preload();

// Import Global Context(s)
import LoadingContext from "~context/loading"

// import global hooks
import useRenderer from "~hooks/useRenderer"
import usePlayheadPosition from "~hooks/usePlayheadPosition"
// import { useWindowSize } from "react-use"

// import higher order components
import withComposition from "~higherOrderComponents/withComposition"

/* Dynamic Import to Resolve Build Issues */
const dat = typeof window !== `undefined` ? require("dat.gui") : null

let setTheTimer = false
let msTimer
let tPrev = 0
let controls
let characterLocations = {}
let cameraKeyframes
let cameraRotations
let cameraTimes
let fakeCamera
let cameraFrame = 0
let followCamOn = {
  value: true,
}
let editorMode = true

const Bush = ({ startTime, endTime }) => {
  // Context
  const { setHarpyCompositionLoading } = useContext(LoadingContext)

  // Hooks
  const playheadPosition = usePlayheadPosition()
  const renderer = useRenderer()
  // renderer.setClearColor( 0x3a14a1, 1 );
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  // State
  const [initialTime, setInitialTime] = useState()
  const [isReady, setIsReady] = useState(false)

  // References
  const cameraReference = useRef()
  const fakeCameraReference = useRef()
  const sceneReference = useRef()
  const clockReference = useRef()
  const effectComposerReference = useRef()

  // Animation Mixers
  const harpyMixerReference = useRef(null)
  const nervousSitMixerReference = useRef(null)
  const nervousEmbraceMixerReference = useRef(null)
  const harpyEmbraceMixerReference = useRef(null)

  const counterReference = useRef(0)
  const planeReference = useRef(null)
  const plane2Reference = useRef(null)
  const knotReference1 = useRef(null)
  const knotReference2 = useRef(null)
  const knotReference3 = useRef(null)
  const guiReference = useRef()
  const timer = useRef()

  // Constants
  const isActive =
    (!startTime && !endTime) ||
    (playheadPosition >= startTime && playheadPosition <= endTime)

  useEffect(() => {
    if (isReady) {
      setHarpyCompositionLoading(false)
    }
  }, [isReady, setHarpyCompositionLoading])

  useEffect(() => {
    if (isReady && isActive && !initialTime) {
      setInitialTime(playheadPosition)
    }
  }, [isReady, isActive, playheadPosition, initialTime])

  useEffect(() => {
    if (cameraReference.current && effectComposerReference.current) {
      const camera = cameraReference.current
      camera.aspect = windowWidth / windowHeight
      camera.updateProjectionMatrix()
      effectComposerReference.current.setSize(windowWidth, windowHeight)
    }
  }, [windowWidth, windowHeight])

  useEffect(() => {
    const initialize = async () => {
      /* Constants and References */
      cameraReference.current = new THREE.PerspectiveCamera(
        60,
        windowWidth / windowHeight,
        0.1,
        1000
      )
      sceneReference.current = new THREE.Scene()
      clockReference.current = new THREE.Clock()
      effectComposerReference.current = new EffectComposer(renderer)
      if (editorMode) {
        guiReference.current = new dat.GUI()
      }
      const camera = cameraReference.current
      const scene = sceneReference.current
      const effectComposer = effectComposerReference.current

      /* 
        Configure Camera 
      */

      camera.position.z = 4

      //  Turn on Orbit Controls
      // if (editorMode) {
      controls = new OrbitControls(camera, renderer.domElement)
      // }

      // SKYBOX skybox
      // const cubeTextureLoader = new THREE.CubeTextureLoader();
      // const skyTexture = cubeTextureLoader.load([
      //   '/nyio/cubemaps/px.jpg',
      //   '/nyio/cubemaps/nx.jpg',
      //   '/nyio/cubemaps/py.jpg',
      //   '/nyio/cubemaps/ny.jpg',
      //   '/nyio/cubemaps/pz.jpg',
      //   '/nyio/cubemaps/nz.jpg',
      // ]);
      // scene.background = skyTexture;
      //SKYBOX skybox

      // Fire effect
      fireMesh(scene)
      fireFlame(scene)
      fireCylinder(scene)
      // Fire effect
      //
      // Burning Bush glb
      let burningBushGltfData
      try {
        burningBushGltfData = await modelLoader("burningBush.glb")
        burningBushGltfData.scene.traverse(function (object) {
          if (object.isMesh) {
            object.material.metalness = 0.7
            object.material.emissiveIntensity = 0
            // console.log(object.material)
          }
        })
      } catch (error) {
        throw new Error(error)
      }
      // burningBushGltfData.scene.position.y = -1;
      burningBushGltfData.scene.position.y = -1.3380604459815393
      burningBushGltfData.scene.position.z = 0.1798451309995286
      burningBushGltfData.scene.scale.x = 1.5
      burningBushGltfData.scene.scale.y = 1.5
      burningBushGltfData.scene.scale.z = 1.5

      scene.add(burningBushGltfData.scene)

      const burningBushControls = new TransformControls(
        camera,
        renderer.domElement
      )
      burningBushControls.attach(burningBushGltfData.scene)

      burningBushControls.addEventListener(
        "dragging-changed",
        function (event) {
          if (controls) controls.enabled = !event.value
          // console.log(burningBushGltfData.scene.position)
          // characterLocations.nervoEmbrace = burningBushData.scene.position
          //dragControls.enabled = !event.value
        }
      )

      scene.add(burningBushControls)

      /* Create Lights */

      let light = new THREE.DirectionalLight(0xffffff)
      light.position.set(10, 10, 10)
      light.target.position.set(0, 0, 0)
      light.castShadow = true
      light.shadow.bias = -0.001
      light.shadow.mapSize.width = 2048
      light.shadow.mapSize.height = 2048
      light.shadow.camera.near = 0.1
      light.shadow.camera.far = 500.0
      light.shadow.camera.left = 10
      light.shadow.camera.right = -10
      light.shadow.camera.top = 10
      light.shadow.camera.bottom = -10
      light.intensity = 0.2
      scene.add(light)

      const helper = new THREE.DirectionalLightHelper(light, 5)
      // scene.add( helper );

      let light2 = new THREE.DirectionalLight(0xffffff)
      light2.position.set(-10, 10, -10)
      light2.target.position.set(0, 0, 0)
      light2.castShadow = true
      light2.shadow.bias = -0.001
      light2.shadow.mapSize.width = 2048
      light2.shadow.mapSize.height = 2048
      light2.shadow.camera.near = 0.1
      light2.shadow.camera.far = 500.0
      light2.shadow.camera.left = 10
      light2.shadow.camera.right = -10
      light2.shadow.camera.top = 10
      light2.shadow.camera.bottom = -10
      light2.intensity = 0.0
      scene.add(light2)
      fakeCameraReference.current = light2
      fakeCamera = fakeCameraReference.current

      const helper2 = new THREE.DirectionalLightHelper(light2, 5)
      // scene.add( helper2 );

      const ambient_light = new THREE.AmbientLight(0x808080)
      scene.add(ambient_light)

      /* Configure Planes that Receive Shadows */
      const planeGeometry = new THREE.PlaneGeometry(1, 1, 32, 32)
      const planeMaterial = new THREE.MeshStandardMaterial({ color: 0xadd8e6 })
      const planeGeometry2 = new THREE.PlaneGeometry(1, 1, 32, 32)
      const planeMaterial2 = new THREE.MeshStandardMaterial({ color: 0xadd8e6 })
      planeReference.current = new THREE.Mesh(planeGeometry, planeMaterial)
      plane2Reference.current = new THREE.Mesh(planeGeometry2, planeMaterial2)

      let texture
      try {
        texture = await new THREE.TextureLoader().load(
          "/nyio/textures/flame.png"
        )
        console.log("Flame Texture loaded")
      } catch (error) {
        throw new Error(error)
      }
      texture.repeat.set(1 / 16, 1 / 8)
      texture.offset.set(0, 0)
      planeReference.current.material.map = texture
      planeReference.current.position.set(0, 1, 0)
      planeReference.current.rotation.set(0, 0, 0, "XYZ")
      planeReference.current.material.transparent = true
      let texture2
      try {
        texture2 = await new THREE.TextureLoader().load(
          "/nyio/textures/flame2.png"
        )
        console.log("Flame Texture loaded")
      } catch (error) {
        throw new Error(error)
      }
      texture2.repeat.set(1 / 16, 1 / 8)
      texture2.offset.set(8 / 16, 3 / 8)
      plane2Reference.current.material.map = texture2
      plane2Reference.current.position.set(0.01, 1, 0.1)
      plane2Reference.current.rotation.set(0, 0, 0, "XYZ")
      plane2Reference.current.material.transparent = true

      /* Configure Controls */
      const light1transformControls = new TransformControls(
        camera,
        renderer.domElement
      )
      light1transformControls.addEventListener(
        "dragging-changed",
        function (event) {
          if (controls) controls.enabled = !event.value
        }
      )
      light1transformControls.attach(light)
      scene.add(light1transformControls)

      const light2transformControls = new TransformControls(
        camera,
        renderer.domElement
      )
      light2transformControls.addEventListener(
        "dragging-changed",
        function (event) {
          if (controls) controls.enabled = !event.value
        }
      )
      light2transformControls.attach(light2)
      scene.add(light1transformControls)

      /* 
        Configure Effect Composers 
      */

      /* Configure Colorify Shaders*/
      effectComposer.addPass(new RenderPass(scene, camera))
      const colorifyShaderPass = new ShaderPass(ColorifyShaderOrange)
      effectComposer.addPass(colorifyShaderPass)

      const bcShader = new ShaderPass(BrightnessContrastShader)
      bcShader.uniforms.tDiffuse.value = 1
      bcShader.uniforms.brightness.value = 0
      bcShader.uniforms.contrast.value = 0
      effectComposer.addPass(bcShader)

      const hsShader = new ShaderPass(HueSaturationShader)
      hsShader.uniforms.tDiffuse.value = 1
      hsShader.uniforms.hue.value = 0
      hsShader.uniforms.saturation.value = 0
      effectComposer.addPass(hsShader)

      /* Configure Unreal Bloom Pass */
      const unrealBloomPass = new UnrealBloomPass(
        { x: 256, y: 256 },
        3.5, // strength
        0.37, // radius
        0.26 // threshold
      )
      effectComposer.addPass(unrealBloomPass)

      /* Configure Bokeh Pass */
      const bokehPass = new BokehPass(scene, camera, {
        focus: 21.5,
        aperture: 0.001,
        maxblur: 0.005,
      })
      effectComposer.addPass(bokehPass)

      /* Configure FxaaPass */
      const fxaaPass = new ShaderPass(FXAAShader)
      const pixelRatio = renderer.getPixelRatio()
      const uniforms = fxaaPass.material.uniforms
      uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio)
      uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio)

      /* Configure Dat.Gui */
      // if (editorMode) {
      // Add the dat-gui
      const gui = guiReference.current

      let color = gui.addFolder("Colors")
      color.addColor(options, "color0").name("ball0")
      color.addColor(options, "color1").name("ball1")
      color.addColor(options, "color2").name("ball2")
      color.addColor(options, "color4").name("steam")
      color.addColor(options, "color5").name("trail")

      // Light Controls
      let guiLights
      guiLights = gui.addFolder("Lights")
      // guiLights.add(light.position, "x", -20, 20).name("TargetX")
      guiLights.add(light, "intensity", 0, 5).name("L1 Intensity")
      guiLights.add(light2, "intensity", 0, 5).name("L2 Intensity")

      gui.add(followCamOn, "value").name("Follow Cam")

      let guiAmbient = gui.addFolder("Ambient Light")
      guiAmbient.addColor(ambient_light, "color")
      guiAmbient.add(ambient_light, "intensity", 0, 1)

      // Brightness Contrast Controls
      let addBrightnessContrastPass = true
      let bcParams = {
        brightness: 0,
        contrast: 0,
        pass: true,
      }
      let guiBrightnessContrast = gui.addFolder("BrightnessContrast")
      guiBrightnessContrast.add(bcParams, "pass").onChange(function (e) {
        if (e) {
          addBrightnessContrastPass = true
          effectComposer.addPass(bcShader)
        } else {
          addBrightnessContrastPass = false
          effectComposer.removePass(bcShader)
        }
      })
      guiBrightnessContrast
        .add(bcShader.uniforms.brightness, "value", -1, 1)
        .name("Brightness")
      guiBrightnessContrast
        .add(bcShader.uniforms.contrast, "value", -1, 1)
        .name("Contrast")

      // Hue Saturation Controls
      let addHSPass = true
      let hsParams = {
        pass: true,
      }
      let guiHSSHader = gui.addFolder("HueSaturation")
      guiHSSHader.add(hsParams, "pass").onChange(function (e) {
        if (e) {
          addHSPass = true
          effectComposer.addPass(hsShader)
        } else {
          addHSPass = false
          effectComposer.removePass(hsShader)
        }
      })
      guiHSSHader.add(hsShader.uniforms.hue, "value", -1, 1).name("Hue")
      guiHSSHader
        .add(hsShader.uniforms.saturation, "value", -1, 1)
        .name("Saturation")

      // Colorify Controls
      let addColorifyPass = false
      effectComposer.removePass(colorifyShaderPass)
      let colorifyParams = {
        color: 0xff00ff,
        pass: false,
      }
      let guiColorify = gui.addFolder("Colorify")
      guiColorify.add(colorifyParams, "pass").onChange(function (e) {
        if (e) {
          addColorifyPass = true
          effectComposer.addPass(colorifyShaderPass)
        } else {
          addColorifyPass = false
          effectComposer.removePass(colorifyShaderPass)
        }
      })
      guiColorify.addColor(colorifyParams, "color").onChange(function () {
        if (addColorifyPass) {
          effectComposer.removePass(colorifyShaderPass)
          const newColorifyShader = new ShaderPass(ColorifyShaderOrange)

          let c = colorifyParams.color
          var r = Math.floor(c / (256 * 256)) / 255
          var g = (Math.floor(c / 256) % 256) / 255
          var b = (c % 256) / 255

          newColorifyShader.uniforms.color.value = {
            isColor: true,
            r: r,
            g: g,
            b: b,
          }

          effectComposer.addPass(newColorifyShader)
        }
      })

      // Bokeh Controls
      let addBokehPass = true
      let bokehParams = {
        aperature: 0.01,
        aspect: 1.7,
        farClip: 1000,
        focus: 21.5,
        maxblur: 0.005,
        nearClip: 0.1,
        pass: true,
      }

      let guiBokeh = gui.addFolder("Bokeh")
      guiBokeh.add(bokehParams, "pass").onChange(function (e) {
        if (e) {
          addBokehPass = true
          effectComposer.addPass(bokehPass)
        } else {
          addBokehPass = false
          effectComposer.removePass(bokehPass)
        }
      })
      guiBokeh.add(bokehPass.uniforms.focus, "value", 0, 200).name("Focus")
      guiBokeh.add(bokehPass.uniforms.aperture, "value", 0, 1).name("Aperture")
      guiBokeh.add(bokehPass.uniforms.aspect, "value", 0, 50).name("Aspect")
      guiBokeh
        .add(bokehPass.uniforms.farClip, "value", 0, 2000)
        .name("Far Clip")
      guiBokeh.add(bokehPass.uniforms.nearClip, "value", 0, 1).name("Near Clip")
      guiBokeh.add(bokehPass.uniforms.maxblur, "value", 0, 1).name("Max Blur")

      // Unreal Bloom Controls
      let guiUnrealBloom = gui.addFolder("Unreal Bloom")
      guiUnrealBloom.add(unrealBloomPass, "enabled").name("pass")
      guiUnrealBloom.add(unrealBloomPass, "radius", 0, 5)
      guiUnrealBloom.add(unrealBloomPass, "strength", 0, 5)
      guiUnrealBloom.add(unrealBloomPass, "threshold", 0, 5)

      let unrealBloomParams = {
        enabled: unrealBloomPass.enabled,
        radius: unrealBloomPass.radius,
        strength: unrealBloomPass.strength,
        threshold: unrealBloomPass.threshold,
      }

      // console.log(light)
      // console.log(ambient_light)

      // Export Settings
      let exportParams = {
        exportSettings: () => {
          prompt(
            "Copy these settings",
            "Colorify: " +
              (colorifyParams.pass
                ? JSON.stringify(colorifyShaderPass.uniforms)
                : " off. ") +
              " Bokeh: " +
              JSON.stringify({
                focus: bokehPass.uniforms.focus.value,
                aperture: bokehPass.uniforms.aperture.value,
                aspect: bokehPass.uniforms.aspect.value,
                maxblur: bokehPass.uniforms.maxblur.value,
              }) +
              " Unreal Bloom: " +
              "({x:256, y:256}, " +
              unrealBloomPass.strength +
              ", " +
              unrealBloomPass.radius +
              ", " +
              unrealBloomPass.threshold +
              ")" +
              " Brightness and Contrast: " +
              "bcShader.uniforms.brightness.value = " +
              bcShader.uniforms.brightness.value +
              ";" +
              "bcShader.uniforms.contrast.value = " +
              bcShader.uniforms.contrast.value +
              ";" +
              " Hue and Saturation: " +
              "hsShader.uniforms.hue.value = " +
              hsShader.uniforms.hue.value +
              ";" +
              "hsShader.uniforms.saturation.value = " +
              hsShader.uniforms.saturation.value +
              ";" +
              " Character Positions " +
              JSON.stringify(characterLocations) +
              " Ambient Light: " +
              JSON.stringify(ambient_light.color) +
              " Light 1: " +
              JSON.stringify(light.position) +
              "Intensity: " +
              light.intensity +
              " Light 2: " +
              JSON.stringify(light2.position) +
              "Intensity: " +
              light2.intensity
          )
        },
      }
      gui.add(exportParams, "exportSettings")
      // }

      function generateSprite() {
        var canvas = document.createElement("canvas")
        canvas.width = 16
        canvas.height = 16

        var context = canvas.getContext("2d")
        var gradient = context.createRadialGradient(
          canvas.width / 2,
          canvas.height / 2,
          0,
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2
        )
        gradient.addColorStop(0, "rgba(255,255,255,1)")
        gradient.addColorStop(0.2, "rgba(0,255,255,1)")
        gradient.addColorStop(0.4, "rgba(0,0,64,1)")
        gradient.addColorStop(1, "rgba(0,0,0,1)")

        context.fillStyle = gradient
        context.fillRect(0, 0, canvas.width, canvas.height)

        var texture = new THREE.Texture(canvas)
        texture.needsUpdate = true
        return texture
      }

      function createPoints(geom) {
        var material = new THREE.PointsMaterial({
          color: 0xffffff,
          size: 0.2,
          transparent: true,
          blending: THREE.AdditiveBlending,
          map: generateSprite(),
          depthWrite: false, // instead of sortParticles
        })

        var cloud = new THREE.Points(geom, material)
        return cloud
      }

      var geom = new THREE.TorusKnotGeometry(15, 39.7, 33, 4)
      // console.log(geom)
      // var geom = new THREE.TorusKnotGeometry(15/2, 39.7/2, 14, 2);

      knotReference1.current = createPoints(geom)
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5
      knotReference1.current.position.y = -10

      knotReference2.current = createPoints(geom)
      knotReference2.current.position.x = -20
      knotReference2.current.position.y = 20
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5

      knotReference3.current = createPoints(geom)
      knotReference3.current.position.x = 20
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5

      // add it to the scene.
      scene.add(knotReference1.current)
      scene.add(knotReference2.current)
      scene.add(knotReference3.current)

      // timer.current = initialTime;
      // console.log(initialTime)
      // // cameraFrame = initialTime;
      // cameraFrame = Math.round(initialTime / (60+55) * cameraTimes.length)
      // console.log(cameraFrame)

      /* Done Configuring Scene */
      setIsReady(true)
    }

    initialize()
  }, [])

  /* Configure Update Function */
  const update = () => {
    // console.log("initialTime", initialTime)
    // console.log("playheadPosition", playheadPosition)
    let d = new Date()
    let t = d.getTime()
    const delta = clockReference.current.getDelta()

    const camera = cameraReference.current

    knotReference1.current.rotation.y -= 0.001
    knotReference1.current.position.y += 0.001

    knotReference2.current.rotation.y -= 0.0005
    knotReference2.current.position.y -= 0.001

    knotReference3.current.rotation.y += 0.001
    knotReference3.current.position.y += 0.0007

    cameraFrame += 1
    fireUpdate(Math.round(cameraFrame) * 10)
  }

  // Request Animation Frame
  useRaf(() => {
    update()
    if (effectComposerReference.current) {
      effectComposerReference.current.render()
    }
  }, isReady && isActive)

  return null
}

const BushWithComposition = withComposition(Bush)

export default BushWithComposition
